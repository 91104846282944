import React from 'react';
import FocusLayout from '../../components/FocusLayout/FocusLayout';
import VerifyGiftcardOwner from './VerifyGiftcardOwner';
import {
  getGiftcardAuthorizationScheme,
  setVerification,
} from '../../actions/giftcard';
import { getPagePresentationKeys } from '../../actions/page';
import { SALES_CHANNELS } from '../../constants';
import { showError } from '../../components/Notifications/notificationTypes';
import { setSalesChannel } from '../../actions/config';
import { getSalesChannelId } from '../../helpers/userStorage';
import { hasSpecialCharacters } from '../../helpers/validator';

const pageName = 'verifyOwner';

const action = async ({ store }, { salesChannelId, type, token, pin }) => {
  const hasSplChar = hasSpecialCharacters(token);
  if (hasSplChar) {
    return undefined;
  }
  const { authorizationScheme } = await store.dispatch(
    getGiftcardAuthorizationScheme({
      exportType: type,
      id: token.toUpperCase(),
    }),
  );

  if (!authorizationScheme) {
    return undefined;
  }

  // if pathname has verification pin save it in store
  if (pin) {
    await store.dispatch(
      setVerification({
        name: 'pin',
        value: pin,
        token: token.toUpperCase(),
        exportType: type,
        pin,
      }),
    );
  }

  const {
    giftcard: { errors },
    config: { salesChannelId: storeSalesChannelId },
  } = store.getState();

  if (errors) {
    showError({
      errors: errors
        .filter(e => !!(e && e.extensions && e.extensions.code))
        .map(({ extensions: { code }, message }) => ({ code, message })),
      context: 'giftcard',
    });
  }
  const localStorageSalesChannelId = getSalesChannelId();
  if (
    salesChannelId &&
    (salesChannelId !== storeSalesChannelId ||
      salesChannelId !== localStorageSalesChannelId)
  ) {
    await store.dispatch(setSalesChannel(salesChannelId));
  }

  const {
    byName: {
      [pageName]: { pageTitle, pageDescription, pageBackgroundImage },
    },
  } = await store.dispatch(getPagePresentationKeys({ pageName }));

  return {
    title: pageTitle || 'Verify giftcard ownership',
    description: pageDescription,
    chunks: ['verify-giftcard-owner'],
    component: (
      <FocusLayout gradient backgroundImage={pageBackgroundImage} centerContent>
        <VerifyGiftcardOwner />
      </FocusLayout>
    ),
    salesChannelId: salesChannelId || SALES_CHANNELS.REDEEM,
    ignoreSalesFlow: true,
  };
};

export default action;
