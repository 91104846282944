const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return expression.test(String(email).toLowerCase());
};

const validatePhone = phone => {
  const expression = /^(00|\+)\d{1,28}$/;

  return expression.test(String(phone));
};

const hasSpecialCharacters = input => {
  if (!input) return false;
  const str = String(input);

  // More comprehensive regex that includes:
  // - Unicode special characters
  // - Control characters
  // - Invisible characters
  // - Any character that is not alphanumeric or standard whitespace
  const specialCharsPattern = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

  return specialCharsPattern.test(str);
};

export { validateEmail, validatePhone, hasSpecialCharacters };
